import * as React from "react"
import './ReddsMangoCytryna.css';
import Creative from "../../components/Creative"
import backgroundRedds from "./images/reddsMangoCytryna_background.png"
import backgroundFruits from "./images/backgroundFruits.png"
import frontImage from "./images/reddsFrontImage.png"
import backImage from "./images/reddsMangoCytryna_backImage.png"
import reddsPackshotLeft from "./images/reddsMangoCytryna_leftPackshot.png"
import reddsPackshotRight from "./images/reddsMangoCytryna_rightPackshot.png"
import video from "./images/reddsMangoCytryna_video.mp4"
import SimpleSidebar from "../../components/layout"

const ReddsMangoCytryna = () => (
  <SimpleSidebar>
    <Creative
      className="reddsMangoCytryna"   
      video={video}
      background={backgroundRedds}
      frontImage={frontImage}
      backImage={backImage}
      heading="Mango & Cytryna"
      tags={["interaction", "game", "puzzle"]}  
      tagBackground="#1c6c31"
      tagColor="#fcd837"
      headingColor="#1c6c31"
    >
      <img src={reddsPackshotRight} className="reddsMangoCytryna_image" />
      <img src={reddsPackshotLeft} className="reddsMangoCytryna_image" />     
    </Creative>
    {/* <img src={backgroundFruits} className="backgroundFruits"/> */}
  </SimpleSidebar>
)

export default ReddsMangoCytryna
